
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "node_modules/select2/src/scss/core";

@import "node_modules/image-picker/source/sass/image-picker";


.preview-image {
  display: block;
  max-width:230px;
  max-height:95px;
  width: auto;
  height: auto;
}